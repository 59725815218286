<template>
  <div v-if="block.properties.type !== 'inject' && block.properties.placeholderId" :id="'ezoic-pub-ad-placeholder-' + block.properties.placeholderId" class="ezoic"></div>
</template>

<style>
.debug .ezoic {
  height: 150px;
  background-color: cadetblue;
}
</style>

<script>
export default {
  name: 'BlockEzoic',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  calculated() {},
  methods: {
    addPlaceholderOnce(array, placeholder) {
      if (!array.includes(placeholder)) {
        array.push(parseInt(placeholder));
        console.log('ezoic-pub-ad-placeholder-' + placeholder + ' - Added To Array');
      }
    },
    ezoic(placeholderList) {
      if (window.ezstandalone !== undefined) {
        window.ezoicPlaceholderArray = window.ezoicPlaceholderArray || [];

        // Add Placeholers to Array
        placeholderList.forEach((placeholder) => {
          this.addPlaceholderOnce(window.ezoicPlaceholderArray, placeholder);
        });

        // Enable Once - Refresh on Change
        window.ezoicRefreshed = false;
        window.ezoicEnabled = window.ezoicEnabled || false;

        // Next Tick Ensures All Enzoic Blocks Are Loaded
        this.$nextTick(() => {
          // On First Load We Must Enable
          if (!window.ezoicEnabled) {
            window.ezstandalone.define(window.ezoicPlaceholderArray);
            window.ezoicPlaceholderArray = null;
            console.log('ezoic defined and array reset');
            window.ezstandalone.enable();
            console.log('ezoic enabled');
            window.ezstandalone.display();
            console.log('ezoic displayed');
            window.ezoicEnabled = true;
            window.ezoicRefreshed = true;
          }

          // On Refrest We hHave To Destroy & Refresh
          if (!window.ezoicRefreshed) {
            window.ezstandalone.destroy();
            console.log('ezoic destroyed');
            window.ezstandalone.define(window.ezoicPlaceholderArray);
            window.ezoicPlaceholderArray = null;
            console.log('ezoic refresh defined and array reset');
            window.ezstandalone.refresh();
            console.log('ezoic refreshed');
            window.ezoicRefreshed = true;
          }
        });
      } else {
        console.log('Error: Missing Ezoic Standalone');
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV == 'production') {
      let placeholderList = this.block.properties.type == 'inject' ? this.block.properties.injectPlaceholderList : this.block.properties.placeholderId;
      if (typeof placeholderList == 'string') {
        placeholderList = placeholderList.split(',');
        this.ezoic(placeholderList);
      }
    }
  },
  beforeUnmount() {
    window.ezoicRefreshed = false;
  },
};
</script>
